'use client';

import React from 'react';
import { Box, Typography } from '@mui/material';

interface CompactCalendarDayProps {
    date: Date;
    isDisabled: boolean;
    isSelected: boolean;
    isCurrentMonth: boolean;
    onClick: (event: React.MouseEvent) => void;
    isHomepage?: boolean;
}

const CompactCalendarDay: React.FC<CompactCalendarDayProps> = ({
    date,
    isDisabled,
    isSelected,
    isCurrentMonth,
    onClick,
    isHomepage = false,
}) => {
    // Handle click events
    const handleInteraction = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation(); // Stop propagation to prevent any parent handlers
        if (isDisabled) return;
        if (e.type === 'touchend') {
            const touchEvent = e as React.TouchEvent;
            const mouseEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
                clientX: touchEvent.changedTouches[0].clientX,
                clientY: touchEvent.changedTouches[0].clientY
            });
            onClick(mouseEvent as any);
        } else {
            onClick(e as React.MouseEvent);
        }
    };

    return (
        <Box
            onClick={handleInteraction}
            onTouchEnd={handleInteraction}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                aspectRatio: '1/1',
                width: '100%',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                borderRadius: isHomepage ? '6px' : '4px',
                backgroundColor: isSelected ? 'rgba(240, 209, 255, 0.3)' : 'transparent',
                border: isSelected ? '1px solid #F0D1FF' : '1px solid transparent',
                opacity: !isCurrentMonth ? 0.4 : isDisabled ? 0.5 : 1,
                color: isCurrentMonth ? '#FFFFFF' : 'rgba(255, 255, 255, 0.6)',
                transition: 'all 0.15s ease',
                WebkitTapHighlightColor: 'transparent',
                touchAction: 'manipulation',
                WebkitTouchCallout: 'none',
                userSelect: 'none',
                position: 'relative',
                minHeight: isHomepage ? '36px' : 'unset',
                '&:hover': {
                    backgroundColor: !isDisabled && !isSelected ? 'rgba(255, 255, 255, 0.1)' : undefined,
                },
                '&:active': {
                    transform: !isDisabled ? 'scale(0.95)' : undefined,
                }
            }}
            role="button"
            tabIndex={isDisabled ? -1 : 0}
        >
            <Typography
                variant="body2"
                sx={{
                    fontWeight: isSelected ? 600 : 400,
                    fontSize: {
                        xs: isHomepage ? '0.85rem' : '0.75rem',
                        sm: isHomepage ? '0.9rem' : '0.8rem'
                    },
                    lineHeight: 1,
                    fontFamily: 'Sans',
                }}
            >
                {date.getDate()}
            </Typography>
        </Box>
    );
};

export default CompactCalendarDay; 